






































import CategoryProductPrice from "~/modules/catalog/category/components/views/CategoryProductPrice.vue";
import { handleDiscountPercentage } from "~/modules/catalog/product/helpers/handleDiscountPercentage";
import { useProductGallery } from "~/modules/catalog/product/composables/useProductGallery";
import { PropType, ref, useRouter, onMounted, reactive, watch } from "@nuxtjs/composition-api";
import type { Product } from "~/modules/catalog/product/types";
import { useProductCard } from '../ProductCard/productCard';
import { useIsMobile } from '@/helpers/mobileDevices';
import { SfSkeleton } from "@storefront-ui/vue";
import { SfColor } from "@storefront-ui/vue";
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: ([Object, null] as PropType<Product>),
    default: null
  },
  hideAddToCart: {
    type: Boolean,
    default: false
  },
  selectedColor: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    isMobile
  } = useIsMobile();
  const router = useRouter();
  const configurableProduct = ref(null);
  const {
    orderMediaGalleryByPosition
  } = useProductGallery(configurableProduct);
  const isLoading = ref(false);
  const selectedImage = ref([]);
  const selectedImageHover = ref([]);
  const mediaGallery = ref(props.product ? props.product?.media_gallery : {});
  const {
    getGalleryByColor,
    isNewProduct,
    getColorConfiguration
  } = useProductCard();
  const newProduct = ref(false);
  const discountProduct = ref(false);
  const productImages = ref([]);
  const currentImage = ref("");
  const configurableColorOptionsArray = ref([]);
  const colorConfiguration = ref(null);
  const selectedColor = ref(null);
  const product = reactive(props.product);
  const totalColor = ref(4);
  watch(() => props.product, newProduct => {
    Object.assign(product, newProduct);
    console.log(product, 'Produit mis à jour');
  });
  onMounted(async () => {
    isLoading.value = true;
    totalColor.value = isMobile ? 2 : 4;
    colorConfiguration.value = await getColorConfiguration(props.product.configurable_options[1]?.values, null, null);
    configurableColorOptionsArray.value = colorConfiguration.value[0];
    selectedColor.value = colorConfiguration.value[1];
    productImages.value = await getGalleryByColor(selectedColor.value, props.product.variants, mediaGallery.value);
    selectedImage.value = props.product?.thumbnail?.url;
    selectedImageHover.value = productImages?.value?.[1]?.url || selectedImage.value;
    currentImage.value = selectedImage.value;
    newProduct.value = await isNewProduct(props.product);
    discountProduct.value = handleDiscountPercentage(props.product) ? true : false;
    await orderMediaGalleryByPosition(props.product);
    isLoading.value = false;
  });
  const onHover = () => {
    const sizeElement = document.querySelector(`.product-size.${CSS.escape(props.product.name.replace(' ', '_'))}`);
    if (sizeElement) {
      sizeElement.style.display = 'flex';
      currentImage.value = selectedImageHover.value;
    }
  };
  const onLeave = () => {
    const sizeElement = document.querySelector(`.product-size.${CSS.escape(props.product.name.replace(' ', '_'))}`);
    if (sizeElement) {
      sizeElement.style.display = 'none';
      currentImage.value = selectedImage.value;
    }
  };
  const viewsMoreColor = (colorUid: string | null) => {
    router.push({
      path: props.product.commonProps.link.fullPath,
      query: {
        color: colorUid
      }
    });
    emit('update:closeSearchModal');
  };
  const redirectToDetailProduct = (url: string) => {
    router.push(url);
    emit('update:closeSearchModal');
  };
  return {
    handleDiscountPercentage,
    isMobile,
    isLoading,
    newProduct,
    currentImage,
    configurableColorOptionsArray,
    selectedColor,
    product,
    totalColor,
    onHover,
    onLeave,
    viewsMoreColor,
    redirectToDetailProduct
  };
};
__sfc_main.components = Object.assign({
  SfSkeleton,
  CategoryProductPrice,
  SfColor
}, __sfc_main.components);
export default __sfc_main;
